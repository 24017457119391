import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Unified API`}</h1>
    <p>{`The Unified API is the preferred API for interfacing with the CoreMedia CMS when writing custom tools and agents and
when writing content delivery applications for the CAE. In particular, it is tightly integrated with the CoreMedia Content Application
Engine and it is available in the Workflow Server for modifying content and for implementing plugin classes.`}</p>
    <p>{`The Unified API allows you to access the Content repository, the Workflow repository and the User repository from custom code. It presents the
properties of documents, versions, folders, users, groups, processes, and tasks in a uniform and object-oriented way.`}</p>
    <h2>{`Use Cases`}</h2>
    <ul>
      <li parentName="ul">{`Content delivery with the CAE`}</li>
      <li parentName="ul">{`Creating command line tools`}</li>
      <li parentName="ul">{`Automated Agents`}</li>
      <li parentName="ul">{`Workflow actions`}</li>
      <li parentName="ul">{`Performer policies`}</li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to use the Unified API in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-12/current/webhelp/uapi-developer-en/content/Introduction.html"
        }}>{`Developer Manual`}</a></li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/coremedia-contributions/coremedia-blueprints-workspace/tree/cmcc-12-2406.0/global/examples/uapi-client"
        }}>{`Unified API Client Example`}</a>{` which
implements a simple counter for publications.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      